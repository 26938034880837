import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/aspn.css"

const AccessPage = () => (
  <Layout>
    <SEO title="Request ASPN Access" />

    <div className="devAccessContainer">
      <h2 className="heading">Gaining Access to ASPN</h2>
      <p>If you are interested in gaining access to ASPN, we will need to add you to our development
        environment. We're contractually obligated to verify your citizenship and government contract
        status before adding you to our systems. </p>

      <h4 className="subheading">Please have your HR department or FSO send an email to jordan.couch@is4s.com with
      the following information:</h4>

      <ul>
        <li>Full Name</li>
        <li>Email Address</li>
        <li>Citizenship <br />(Proof of citizenship accepted:)
          <ul>
            <li>Email from an FSO or HR</li>
            <li>DISS visit request sent to our FSO</li>
          </ul>
        </li>
      </ul>

      <h5>If your company policy does not allow the release of this kind of information through
        e-mail, we can also use a DISS visit request. </h5>

      <div>
        <h4 className="subheading">SMO information for DISS visit request:</h4>
        <ul>
          <li>Date: Current Date</li>
          <li>SMO code: 8FZS9 - IS4S-DAY-1</li>
          <li>Cage code: 8FZS9</li>
          <li>Address: 4200 Colonel Glenn Hwy. Suite 700. Beavercreek, OH 45431</li>
          <li>Security POC: Jordan Couch</li>
          <li>Email: jordan.couch@is4s.com</li>
          <li>937-776-3525</li>
          <li><b>Requestor's email address</b></li>
        </ul>
      </div>
      <h5>Please also have one of your government sponsors send an email
        to jordan.couch@is4s.com confirming you have currency on a government contract.</h5>
    </div>
  </Layout>
)

export default AccessPage
